var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-top-dashboard',{attrs:{"neutral":""}}),_c('v-container',{staticClass:"pt-0 px-6 mt-n16 container-general",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mt-n3",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card-shadow v-card-blue px-0"},[_c('div',{staticClass:"card-header card-border-bottom mb-0"},[_c('div',{staticClass:"position-relative"},[_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{staticClass:"table thead-light table-only-data",attrs:{"footer-props":{
                    'items-per-page-options': [5, 10, 15, 25],
                    'disable-pagination': true,
                    'items-per-page-text': _vm.$t('Items por página'),
                  },"disable-pagination":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.content,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"server-items-length":_vm.totalVerified,"options":_vm.options,"dense":"","loading":_vm.loading,"loading-text":_vm.$t('Cargando datos... Espere por favor.'),"no-data-text":_vm.$t('No hay resultados.')},on:{"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.id)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.description)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.company_employee_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.partner_name)+" ")]),_c('td',[(item.valid)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Validado'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('Pendiente'))+" ")])]),_c('td',[_c('v-row',{staticClass:"pa-5",attrs:{"align":"center","justify":"center"}},[_c('v-btn-toggle',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btns_table ",attrs:{"to":{ name: 'GoogleVerifiedRequest', params: { id: item.id }},"color":"white","elevation":"0"}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-edit"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Editar")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btns_table btns_table_eliminar",attrs:{"color":"white","elevation":"0"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-trash"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Eliminar")))])])],1)],1)],1)])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('p',{staticClass:"tit_card"},[_vm._v(" Google Verified SMS ")]),_c('v-spacer'),_c('v-btn',{staticClass:"text-transform-none float-right theme-secondary-btn",attrs:{"to":{name: 'GoogleVerifiedRequest'},"elevation":"0","large":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" fas fa-plus-circle ")]),_vm._v(" "+_vm._s(_vm.$t("Nueva solicitud"))+" ")],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',{staticClass:"card-shadow nocard"},[_c('div',{staticClass:"header_modal gradient-text-color text-h3"},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":"#ffffff"}},[_vm._v(" fas fa-exclamation-triangle ")]),_vm._v(" "+_vm._s(_vm.$t('Eliminar verificación'))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-white float-right text-white",attrs:{"color":"transparent","elevation":"0","icon":"","small":""},on:{"click":_vm.closeDelete}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" far fa-times-circle ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Cerrar')))])])],1),_c('v-card-text',[_c('p',{staticClass:"text-h3 pt-5",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("¿Esta seguro de eliminar esta verificación?"))+" "),_c('br')])]),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"text-capitalize float-right px-6",attrs:{"ripple":false,"elevation":"0","large":"","rounded":"","text":"","color":"blue-grey darken-4  white--text"},on:{"click":_vm.closeDelete}},[_vm._v(" "+_vm._s(_vm.$t('Cancelar'))+" ")]),_c('v-btn',{staticClass:"text-capitalize float-right px-6 px-5",attrs:{"ripple":false,"elevation":"0","large":"","color":"red darken-1 text-white"},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" "+_vm._s(_vm.$t('Aceptar'))+" ")])],1)],1)],1)],1)]},proxy:true}])})],1),_c('v-card-actions',{staticClass:"py-0 my-0 mt-lg-n13"},[_c('v-row',[_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","length":_vm.pageCount,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }